import React from 'react';

//User Pages
const PageCoverLogin = React.lazy(() => import('./pages/Pages/User/PageCoverLogin'));
const PageCoverRePassword = React.lazy(() => import('./pages/Pages/User/PageCoverRePassword'));
const PageCoverSignup = React.lazy(() => import('./pages/Pages/User/PageCoverSignup'));
const userActivation = React.lazy(() => import('./pages/Pages/User/user-activation'));

const routes = [
    //routes without Layout
    
    //User Pages
    { path: '/password', component: PageCoverRePassword, isWithoutLayout : true},
    { path: '/signup', component: PageCoverSignup, isWithoutLayout : true },
    { path: '/login', component: PageCoverLogin, isWithoutLayout : true },
    { path: '/u/:id', component: userActivation, isWithoutLayout : true },

    //Index Main
    { path: '/index', component: PageCoverLogin },

    //Root
    { path: '/', component: PageCoverLogin, isWithoutLayout : true }


];

export default routes;