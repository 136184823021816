import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';

//Import Switcher
import ThemeSwitcher from "./theme-switcher";

// Layout Components
const Footer = React.lazy(() => import('./Footer'));
const FooterWithoutMenuLightSocialOnly = React.lazy(() => import('./FooterWithoutMenuLightSocialOnly'));

class Layout extends Component {

  Loader = () => {
    return (
      <div id="ctnr">
          <div className="ldr">
              <div className="ldr-blk"></div>
              <div className="ldr-blk an_delay"></div>
              <div className="ldr-blk an_delay"></div>
              <div className="ldr-blk"></div>
          </div>
      </div>
    );
}

  render() {
    return (
      <React.Fragment>
          <Suspense fallback = {this.Loader()} >
            {this.props.children}
          </Suspense>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
